//
// About Custom
//

.step3{
    label.error{
        opacity: 0;
        font-size: 0;
    }

    .submitted{
        label.error{
            opacity: 1;
            font-size: 20px;
        }
    }
}

.main-how{
    .block-bottom{
        .info-header{
            background-color: #013b69;
            padding: 60px 92px 0 66px;
            bottom: 135px;
            left: 15%;
            h1{
                line-height: 62px;
            }
            p{
                line-height: 30px;
                font-weight: 100;
            }
            .btn{
                background-color: #fff;
                color: #013b69;
                margin-top: 70px;
                @media (max-width: 1170px) {
                    height: 38px;
                    line-height: 38px;
                    border-radius: 5px;
                    font-family: Lato;
                    font-size: 11px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 0 24px;
                    display: inline-block;
                    text-decoration: none;
                }
                &:hover{
                    background-color: #8fcafa;
                    color: #fff;
                }
            }
        }
        figure{
            left: 40%;
            width: 60%;
        }
        @media (max-width: 1170px) {
            height: 267px;
            margin-bottom: 140px;
            figure{
                left: 0;
                width: 100%;
                height: 100%;
            }
            .info-header{
                right: auto;
                left: 25px;
                transform: translateX(0);

                width: 250px;
                height: 218px;
                padding: 20px;
                text-align: left;

                bottom: -125px;

                h1{
                    line-height: 24px;
                    font-size: 20px;
                    padding-right: 20px; 
                }
                p{
                    line-height: 15px;
                    font-size: 13px;
                    padding: 20px 20px 0 0;
                }
                .btn{
                    background-color: #fff;
                    color: #013b69;
                    margin-top: 15px;
                    
                }
            }
        }
    }

    .how{
        padding: 220px 0;
        background-color: #f8f8f8;
        .wrap{

        }
        .col{
            display: inline-block;
            width: 49%;
            padding-right: 20px;
            &.col1{
                h2{
                    font-family: Lato;
                    font-size: 50px;
                    line-height: 60px;
                    padding-bottom: 40px;
                }
                p{
                    font-family: Lato;
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 1.640625;
                }
            }
            &.col2{
                text-align: right;
                img{
                    position: relative;
                    left: 20px;
                }
            }
    
        }
        figure{
            img{
                
            }
    
        }
    }
    section.infos{
        width: 100%;
        font-size: 0;
        margin-top: 75px;
        
        .content{
            text-align: center;
            position: relative;
        }
        i.icon{
            display: inline-block;
            position: relative;
            text-align: center;

        }
        h3{
            font-family: Lato;
            font-size: 30px;
            font-weight: 300;
            color: #013b69;
            padding-top: 30px;
            
            transition: color 0.2s ease;
        }
        p{
            // color: #fff;
            padding: 40px 23%;
            font-family: Lato;
            font-size: 22px;
            font-weight: 100;
            line-height: 1.34765625;
            color: #000;
            display: block;
        }

        .info{
            width: 33.33%;
            font-size: 14px;
            padding-bottom: 30px;
            display: inline-block;
            vertical-align: top;
            @media (max-width:1200px) {
                height: auto;
            }
        }
        @media (max-width:980px) {
            padding: 30px 0;
            i.icon{
                line-height: auto;
            }
            .content{
                top: unset;
                transform: translateY(0);
            }
            p{
                // color: #fff;
                padding: 20px 30px;
                font-size: 15px;
            }
            .info{
                width: 100%;
                height: auto;
                padding: 40px 0;
            }
        }

    }
}

.modal-adquira, 
.modal-solicite
{
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.2s ease;
    &.open{
        opacity: 1;
        pointer-events: unset;
    }
}

.modal-solicite,
.modal-adquira{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    .header-modal{
        height: 313px;
        width: 100%;
        background: url(../img/bg-header-modal.jpg);
        color: #fff;
        padding: 40px 58px;
        position: relative;
        h3{
            max-width: 531px;
            font-size: 50px;
            font-weight: 100;
            line-height: 56px;
        }
        p{
            font-size: 22px;
            font-weight: 100;
            max-width: 380px;
            padding-top: 20px;
            line-height: 27px;
        }
        i.close{
            position: absolute;
            top: 15px;
            right: 15px;
            width: 36px;
            height: 36px;
            z-index: 9999;
            display: inline-block;
            background:url(../img/close-modal.png) center no-repeat;
            cursor: pointer;
        }
        @media (max-width:980px) {
            height: 138px;
            padding: 10px 30px;
            position: relative;
            h3{
                font-size: 20px;
                line-height: 24px;
            }
            p{
                font-size: 13px;
                line-height: 15px;
            }
            i.close{
                top: 5px;
                right: 5px;
            }
        }
    }
    
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.85);
        z-index: 1;
    }
    .box{
        background-color: #fff;
        width: 888px;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;

        max-height: 90vh;
        overflow-y: scroll;
        
        .forms{
            padding: 40px 35px 80px 35px;
            @media (max-width:980px) {
                padding: 7px 15px 30px 15px;
            }

            .wpcf7{
                position: relative;
            }

            .wpcf7-response-output{
                position: absolute;
                width: 100%;
                margin: 0;
                font-size: 18px;
                font-weight: 100;
                color: #000;
                border: none;
                bottom: -128px;
                text-align: center;
                padding: 20px 0;
                @media (max-width:980px) {
                    bottom: -71px;
                }
                @media (max-width:600px) {
                    bottom: -131px;
                }

                &.wpcf7-validation-errors{
                    background: #ff000059;
                }

                &.wpcf7-mail-sent-ok{
                    background: #00ff1f59;
                }

            }

            .steps{
                
            }
            .step1{
    
            }
            .step2{
                position: relative;
                
            }
            .step3{
                .hidden{
                    position: absolute;
                    left: -9999px;
                }
                .wpcf7-not-valid-tip{
                    display: none !important;
                }
                form{
                    p{
                        margin-block-start: 0;
                        margin-block-end: 0;
                        margin: 0;
                        padding: 0;
                        height: 0;
                    }
                }
            }
            .step{
                display: none;
                &.step-active{
                    display: block;
                }
                input[type=submit] {
                    width: 182px;
                    background-color: #3b95cb !important;
                    text-transform: uppercase;
                    margin-top: 0;
                }
                .backbtn{
                    height: 65px;
                    line-height: 65px;
                    border-radius: 5px;
                    font-family: Lato;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 0;
                    text-align: center;
                    display: inline-block;
                    text-decoration: none; 
                    width: 182px;
                    background-color: #3b95cb;
                    color:#fff;
                }
            }
            @media(max-width:980px){
                .step{
                    div.wpcf7 .ajax-loader{
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    .backbtn{
                        width: 132px;
                        height: 45px;
                        line-height: 45px;
                        font-size: 16px;
                        float: left;
                        text-align: center;
                        padding: 0;
                    }
                    input[type=submit] {
                        width: 132px !important;
                        background-color: #3b95cb !important;
                        text-transform: uppercase;
                        margin-top: 0;
                        padding-left: 7px;
                    }
                }
            }
        }
    }
}

.ui-autocomplete{
    background: #fff;
    border: #3b95cb 1px solid;
 
    li{
        line-height: 50px;
        height: 50px;
        padding: 0 15px;
        cursor: pointer;
        color: #3b95cb;
        font-size: 20px;
        &:hover{
            background-color: #3b95cb;
            color: #fff;
        }
    }
}

.ui-state-active, 
.ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active, 
a.ui-button:active, 
.ui-button:active, 
.ui-state-active.ui-button:hover 
{
  border: 0;
  background: transparent;
  color: #fff;
}



