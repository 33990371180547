//
// Dúvidas Custom
//



.main-contact{
    .header-main{
        margin-bottom: 220px;
        .info-header{
            background: #3b95cb;
            bottom: -100px;
            h1{
                line-height: 62px;
            }
            p{
                line-height: 30px;
            }
        }
        figure{
           width: 100%;
        }
        @media (max-width: 1170px) {
            height: 226px;
            margin-bottom: 140px;
            .info-header{
                right: 0;
                left: auto;
                transform: translateX(0);

                width: 250px;
                height: 200px;
                padding: 20px;
                text-align: right;

                h1{
                    line-height: 24px;
                    font-size: 20px;
                }
                p{
                    line-height: 15px;
                    font-size: 13px;
                    padding: 20px 0 0 30px;
                }
            }
        }
    }
    
    
    .contact{
        .wrap{
            width: 954px;
            max-width: 95%;
            padding-bottom: 160px;
        }
        h2{
            font-size: 40px;
            color: #000;
            font-weight: 300;
            margin: 20px 0;
        }

        .form{
            position: relative;
            .wpcf7-response-output{
                position: absolute;
                width: 100%;
                margin: 0;
                font-size: 18px;
                font-weight: 100;
                color: #000;
                border: none;
                bottom: -128px;
                text-align: center;
                padding: 20px 0;
                @media (max-width:980px) {
                    bottom: -71px;
                }

                &.wpcf7-validation-errors{
                    background: #ff000059;
                }

                &.wpcf7-mail-sent-ok{
                    background: #00ff1f59;
                }

            }

            select,
            input{
                height: 55px;
                border: solid 1px #3b95cb;
                display: inline-block;
                width: 100%;
            }
            
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                border-radius: 0;
            }
            
            select::-ms-expand {
                display: none;
            }

            textarea{
                border: solid 1px #3b95cb;
                width: 100%;
                padding: 20px;
            }

            textarea,
            select,
            input{
                padding-left: 20px;
                font-size: 20px;
            }

            .w100{
                width: 100%;
                display: inline-block;
            }
            .line{
                width: 100%;
                text-align: justify;
                text-align-last: justify;
            }
            .w50{
                width: 49%;
                display: inline-block;
                vertical-align: top;
            }

            label{
                width: 100%;
                font-size: 20px;
                font-weight: 100;
                color: #000;
                padding: 20px 0;
                display: inline-block;
                text-align: left;
                text-align-last: left;
            }
            input[type=submit]{
                background-color: #013b69;
                height: 65px;
                border-radius: 5px;
                border: 0;
                color: rgb(255, 255, 255);
                margin-bottom: 30px;
                width: 325px;
                max-width: 90%;
                float:right;
                @media (max-width:980px) {
                    float: unset;
                }
            }

            select,
            textarea,
            input{
                margin-top: 13px;
            }

            .your-name input{
                width: 100%;
            }
        }
    }
}

.contact-footer{
    width: 100%;
    height: 514px;
    position: relative;
    background-size: cover;
    img{
        position: absolute;
        top: -240px;
        left: 0;
    }
    @media (max-width:980px) {
        width: 100%;
        height: 38vw;
    }
}

.form{
    select,
    input{
        height: 55px;
        border: solid 1px #3b95cb;
        display: inline-block;
        width: 100%;
    }
    
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        border-radius: 0;
    }
    
    select::-ms-expand {
        display: none;
    }

    textarea{
        border: solid 1px #3b95cb;
        width: 100%;
    }

    textarea,
    select,
    input{
        padding-left: 20px;
        font-size: 20px;
    }

    .w100{
        width: 100%;
        display: inline-block;
    }
    .line{
        width: 100%;
        text-align: justify;
        text-align-last: justify;
    }
    .w50{
        width: 49%;
        display: inline-block;
        vertical-align: top;
    }

    label{
        width: 100%;
        font-size: 20px;
        font-weight: 100;
        color: #000;
        padding: 20px 0;
        display: inline-block;
        text-align: left;
        text-align-last: left;
        &.error{
            display: none;
        }
    }
    input[type=submit]{
        background-color: #013b69;
        height: 65px;
        border-radius: 5px;
        border: 0;
        color: rgb(255, 255, 255);
        margin-bottom: 30px;
        width: 325px;
        max-width: 90%;
        float:right;
        cursor: pointer;
        padding-left: 7px !important;
        @media (max-width:980px) {
            float: unset;
        }
    }

    select,
    textarea,
    input{
        margin-top: 13px;

    }

    .your-name input{
        width: 100%;
    }

    @media(max-width:980px){
        .w50{
            width: 100% !important;
            display: inline-block;
            vertical-align: top;
        }
    
        label{
            width: 100%;
            font-size: 14px !important;
            font-weight: 100;
            color: #000;
            padding: 20px 0;
            display: inline-block;
            text-align: left;
            text-align-last: left;
        }
        input[type=submit]{
            width: 132px !important;
            height: 45px;
            font-size: 16px;
            float: right !important;

        }
        label{
            padding: 10px 0 !important;
        }
        select, input{
            height: 42px !important;
            font-size: 14px !important;
        }
        select, textarea, input{
            margin-top:8px !important;
        }

    }

    
}