.main-download{
    &.sitemap{
        ul{
            margin-right: 30px;
        }
        .columns{
            display: flex;
            justify-content: center;
        }
        .column-one{
            padding: 0px 50px 0px 100px;
            margin-top: 50px;
            margin-bottom: 150px;
            margin-right: 0;
        }
        .column-two{
            padding: 0px 50px;
            margin-top: 50px;
            border-left: 1px solid #3b95cb;
            margin-bottom: 150px;
            margin-right: 0;

        }
    }
    .header-download{
    
    }
    figure{
        display: table;
        width: 100%;
        margin: 0 auto;
        background-color: #3b95cb;
        text-align: center;
        font-size: 0;
        img{
            max-width: 90%;
        }
    }
    .info-header{
        width: 100%;
        background-color: #3b95cb;
        text-align: center;
    
        .wrap{
            padding-top: 140px;
        }
        h1{
            font-size: 55px;
            font-weight: 100;
            color: #ffffff;
        }
        p{
            width: 875px;
            max-width: 100%;
            margin: 0 auto;
            font-size: 22px;
            font-weight: 100;
            line-height: 27px;
            letter-spacing: normal;
            color: #ffffff;
            padding: 70px 0;
            display: block;
        }
        @media (max-width:980px) {
            .wrap{
                padding-top: 26vw;
            }
            h1{
                font-size: 25px;
            }
            p{
                font-size: 14px;
                padding: 40px 20px;
                line-height: 16px;
            }
        }
        @media (max-width:980px) {
            .wrap{
                padding-top: 20vw;
            }
            p{
                font-size: 12px;
                padding: 40px 20px 20px;
                line-height: 16px;
            }
        }
    
        
    
        .buttons{
            .get-in,
            .btn{
                margin: 0 12px 80px;
                transition: opacity 0.2s ease;
                &.btn-download{
                    background-color: #fff;
                    color: #013b69;
                }
                &.get-in{
                    background-color: #000;
                    color: #fff;
                }
                &:hover{
                    opacity: 0.88;
                }

                @media (max-width:980px) {
                    margin: 0 12px 20px;
                }
    
            }
            
            .get-in{
                display: inline-block;
                vertical-align: bottom;
                border-radius: 6px;
                height: 65px;
                position: relative;

                img{
                    transform: translateY(-50%);
                    top: 50%;
                    position: relative;
                }
                @media (max-width:980px) {
                    height: 38px;
                    line-height: 38px;
                    img{
                        max-height: 100%;
                        width: auto;
                    }
                }
            }

            @media (max-width:980px) {
                padding-bottom: 20px;
            }
        }
    
        .code{
            text-align: left;
            padding-bottom: 80px;
    
    
            .commands{
                h2{
                    font-size: 30px;
                    font-weight: 300;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #013b69;
                    margin: 43px 0 30px;
    
                }
                .codebox{
                    div{
                        border-radius: 5px;
                        background-color: #000000;
                        padding: 30px 40px;
    
                        font-family: monospace;
                        font-size: 14px;
                        font-weight: 100;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #ffffff;
                        line-height: 32px;
                        @media (max-width:  980px) {
                            padding: 20px 20px;
                            font-family: Lato;
                            font-size: 12px;
                        }
                        
                    }
    
                }
    
            }
    
        }
    
    }
    
    
    .moreinfos{
        padding: 100px 0;
        text-align: center;
        .wrap-text{
            max-width: 800px;
            margin: 0 auto;
            @media (max-width:980px) {
                max-width: 94%;
            }
            ul{
                position: relative;
                z-index: 9;
                li{
                    text-align: left;
                    margin-top: 12px;
                    display: block;
                    a{
                        color: #000;
                        font-size: 16px;
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    ul{
                        padding-bottom: 0px;
                        padding-left: 20px;
                        li{
                            list-style: circle;
                            display: list-item;
                        }
                    }

                }
            }
            p{
                font-size: 22px;
                color: #000;
                font-weight: initial;
                padding: 35px 0;
                font-weight: 100;
                width: auto;
                max-width: 100%;
                display: block;
                margin: initial;
                text-align: left;
                line-height: 30px;
                @media (max-width:980px) {
                    word-break: break-word;
                }
                b{
                    font-weight: 500;
                }
            }
        }
        h2{
            font-size: 30px;
            color: #000;
            font-weight: 300;
        }
        p{
            font-size: 22px;
            color: #000;
            font-weight: 100;
            padding: 35px 0;
            width: 800px;
            max-width: 100%;
            display: block;
            margin: 0 auto;
            line-height: 30px;
        }
        .links{
            padding-bottom: 40px;
            h3{
                font-size: 22px;
                color: #013b69;
                display: inline-block;
                margin-right: 20px;
                font-weight: 100;
            }
            a{
                font-size: 18px;
                color: #000;
                transition: color 0.2s ease;
                &:hover{
                    color: #3b95cb;
                }
            }
    
        }
        @media (max-width:980px) {
            padding: 60px 0;
            h2{
                font-size: 24px;
            }
            p{
                font-size: 15px;
                line-height: 17px;
            }
            .links{
                padding-bottom: 20px;
                font-size: 12px;
                h3{
                    font-size: 12px;
                }
                a{
                    font-size: 12px;
                }
            }
        }
        
    }
    
    .footer-figure{
        width: 100%;
        height: 514px;
        position: relative;
        clear: both;
        background-size: cover;
        &.footer-mobile{
            display: none;
        }
        img{
            position: absolute;
            top: -240px;
            left: 0;
        }
        @media (max-width:980px) {
            width: 100%;
            height: 38vw;
            &.footer-desktop{
                display: none;
            }
            &.footer-mobile{
                display: block;
            }
            img{
                width: 28%;
                top: 0;
                transform: translateY(-60%);
            }
        }
    }

}