.main-features{
    @media (max-width: 680px) {
        padding-bottom: 2660px;
    }
    @media (max-width: 420px) {
        padding-bottom: 2190px;
    }
    @media (max-width: 376px) {
        padding-bottom: 2000px;
    }
    @media (max-width: 356px) {
        padding-bottom: 1650px;
    }
    .header-main{
        .info-header{
            background-color: #01467d;
            text-align: right;
            padding: 140px 66px 0 142px;
            left: 11%;
            h1{

            }
            p{
                padding: 40px 0 0 70px;
                line-height: 30px;
            }
        }
        figure{
            left: 44%;
            width: 56%;
            &.image-mobile{
                display: none;
            }

            img{
                width: 100%;
            }
        }

        @media (max-width: 680px) {
            height: 226px;
            margin-bottom: 140px;
            figure{
                display: none;
            }
            figure.image-mobile{
                display: block !important;
            }
            .info-header{
                left: 0;
                transform: translateX(0);

                width: 250px;
                height: 200px;
                padding: 20px;
                text-align: right;
                bottom: -30px;

                h1{
                    line-height: 24px;
                    font-size: 20px;
                }
                p{
                    line-height: 15px;
                    font-size: 13px;
                    padding: 20px 0 0 30px;
                }
            }
        }

        @media (max-width: 600px) {
            .info-header{
                bottom: -134px;
            }
        }
    }

    .grid{
        padding: 110px 0 80px;
        font-size: 0;
        @media (max-width:630px) {
            transform: scale(0.8);
        }
        .block{
            display: inline-block;
            width: calc(50% - 15px);
            height: 534px;
            position: relative;
            &:nth-child(2n+2){
                margin-left: 25px;
            }
            @media (max-width:1254px) {
                width: 587px;
                margin: 0 auto;
                display: block;
                &:nth-child(2n+2) {
                    margin-left: auto;
                }
            }
        }
        .item{
            display: block;
            position: absolute;
            text-decoration: none;

            .overlay{
                position: relative;
                width: 100%;
                height: 100%;
                background: rgba(1, 59, 105, 0);
                transition: background 0.2s ease;

            }
            
            h2{
                font-family: Lato;
                font-size: 30px;
                font-weight: 100;
                color: #ffffff;
                text-align: right;
                text-decoration: none;
                position: absolute;
                bottom: 20px;
                right: 20px;
                &::before{
                    content: "";
                    background:url(../img/arrow.png) center no-repeat;
                    display: block;
                    height: 22px;
                    width: 31px;
                    position: absolute;
                    right: 20px;
                    top: -30px;
                    opacity: 0;
                    transition: opacity 0.16s ease, right 0.12s ease;
                }
            }


            &:hover{
                .overlay{
                    background: rgba(1, 59, 105, 0.66);
                }
                h2{
                    &::before{
                        right: 0px;
                        opacity: 1;
                    }
                }
            }

            // block 1
            &.item1color{
                width: 223px;
                height: 113px;
                background-color: #3b95cb;
                top: 0;
                left: 0;
            }
            &.item1{
                width: 345px;
                height: 245px;
                top: 0;
                left: 243px;
            }

            &.item2{
                width: 223px;
                height: 379px;
                top: 133px;
                left: 0;
            }

            &.item3{
                width: 345px;
                height: 245px;
                top: 265px;
                left: 243px;
            }

            // block 2
            &.item4color{
                width: 102px;
                height: 510px;
                background-color: #013b69;
                top: 0;
                left: 0;
            }
            &.item4{
                width: 467px;
                height: 512px;
                top: 0;
                left: 122px;
            }

            // block 3
            &.item5{
                width: 588px;
                height: 245px;
                top: 0;
                left: 0;
            }
            &.item6{
                width: 588px;
                height: 246px;
                top: 265px;
                left: 0;
            }

            // block 4
            &.item7{
                width: 344px;
                height: 243px;
                top: 0;
                left: 0;
            }
            &.item8{
                width: 223px;
                height: 378px;
                top: 0;
                left: 364px;
            }
            &.item9{
                width: 345px;
                height: 244px;
                top: 265px;
                left: 0;
            }
            &.item9color{
                width: 223px;
                height: 113px;
                top: 398px;
                left: 364px;
                background-color: #3b95cb;
            }

            // block 5
            &.item10color{
                width: 102px;
                height: 510px;
                background-color: #013b69;
                top: 0;
                left: 0;
            }
            &.item10{
                width: 467px;
                height: 508px;
                top: 0;
                left: 120px;
            }
            
            // block 6
            &.item11{
                width: 587px;
                height: 245px;
                top: 0;
                left: 0;
            }
            &.item12color{
                width: 102px;
                height: 241px;
                background-color: #346b97;
                top: 265px;
                left: 0;
            }
            &.item12{
                width: 463px;
                height: 244px;
                top: 265px;
                left: 122px;
            }
        }

        @media (max-width: 680px) {
            position: absolute;
            top: -155px;
            left: 100%;
            transform: scale(0.7) translateX(-150%);
            max-width: 100%;
            .block{
                margin-left: 0;
                margin-right: unset;
                float: left;
            }
        }
        @media (max-width: 600px) {
            position: absolute;
            top: -190px;
            left: -63px;
            transform: scale(0.66) translateX(0);
            max-width: 100%;
        }
        @media (max-width: 376px) {
            position: absolute;
            top: -260px;
            left: -70px;
            transform: scale(0.6) translateX(-0);
            max-width: 100%;
            .block{
                margin-left: 0;
                margin-right: unset;
                float: left;
            }
        }

        @media (max-width: 356px) {
            position: absolute;
            top: -450px;
            left: -69px;
            transform: scale(0.5) translateX(0);
            max-width: 100%;
            .block{
                margin-left: 0;
                margin-right: unset;
                float: left;
            }
        }
    }

    
}

.modal-features{
    position: fixed;
    max-height: 100%;
    
    z-index: -1;
    opacity: 0;
    // pointer-events: none;
    transition: opacity 0.2s ease;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.8);
    }

    .block{
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        background-color: #ffffff;
        width: 888px;
        position: absolute;
        max-width: 95%;
        max-height: 95vh;
        overflow-y: auto;
    }

    .close{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 36px;
        height: 36px;
        display: inline-block;
        z-index: 9999;
        background:url(../img/close-modal.png) center no-repeat;
        @media (min-width: 1200px) {
            &:hover{
                cursor:pointer;
                opacity:0.8;
            }
        }
    }

    &.open{
        opacity: 1;
        z-index: 9999;
        pointer-events: unset;
    }

    figure{
        position: relative;
        width: 100%;

        img{
            max-width: 100%;
        }
    }
    .content{
        padding: 50px 40px;
        @media (max-width:600px) {
            padding: 20px 15px;
        }
        h3{
            font-size: 30px;
            color: #013b69;
        }
        .subtitle{
            font-size: 22px;
            font-weight: 100;
            color: #4f4f4f;
            padding: 20px 0 27px;
            display: inline-block;
            @media(max-width: 600px){
                padding: 10px 0 15px;
            }
        }
        .text{
            font-family: Lato;
            font-size: 22px;
            font-weight: 100;
            line-height: 1.40625;
            color: #000000;
            @media (max-width:600px) {
                line-height: 1.2;
            }
        }
    }
    @media (max-width:980px) {
        .content{
            text-align: center;
            h3{
                font-size: 24px;
            }
            .subtitle{
                font-size: 15px;
            }
            .text{
                font-size: 13px;
            }
        }
    }
}

.modal-teste{
    position: fixed;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateY(-500%) translateX(-50%);
    z-index: -1;
    opacity: 0;
    // pointer-events: none;
    transition: opacity 0.2s ease;
    max-width: 95%;
        max-height: 95vh;
        overflow-y: auto;

    .close{
        position: absolute;
        top: 15px;
        right: 15px;
        width: 36px;
        height: 36px;
        display: inline-block;
        z-index: 9999;
        background:url(../img/close-modal.png) center no-repeat;
        @media (min-width: 1200px) {
            &:hover{
                cursor:pointer;
                opacity:0.8;
            }
        }
    }

    &.open{
        opacity: 1;
        z-index: 9999;
        transform: translateX(-50%) translateY(-50%);
    }

    figure{
        position: relative;
        width: 100%;

        img{
            max-width: 100%;
        }
    }
    .content{
        padding: 50px 40px;
        @media (max-width:600px) {
            padding: 20px 15px;
        }
        h3{
            font-size: 30px;
            color: #013b69;
        }
        .subtitle{
            font-size: 22px;
            font-weight: 100;
            color: #4f4f4f;
            padding: 20px 0 27px;
            display: inline-block;
            @media(max-width: 600px){
                padding: 10px 0 15px;
            }
        }
        .text{
            font-family: Lato;
            font-size: 22px;
            font-weight: 100;
            line-height: 1.40625;
            color: #000000;
            @media (max-width:600px) {
                line-height: 1.2;
            }
        }
    }
    @media (max-width:980px) {
        .content{
            text-align: center;
            h3{
                font-size: 24px;
            }
            .subtitle{
                font-size: 15px;
            }
            .text{
                font-size: 13px;
            }
        }
    }
}