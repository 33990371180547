//
// About Custom
//

.main-sobre{
    .header-main{
        
        .info-header{
            h1{
                line-height: 62px;
            }
            p{
                line-height: 30px;
            }
        }
        figure{
            &.image-mobile{
                display: none;
            }
        }
        @media (max-width: 610px) {
            height: 226px;
            margin-bottom: 140px;
            figure{
                display: none;
            }
            figure.image-mobile{
                display: block !important;
            }
            .info-header{
                left: unset;
                right: 0;
                transform: translateX(0);

                width: 250px;
                height: 200px;
                padding: 20px;
                text-align: right;
                bottom: -134px;

                h1{
                    line-height: 24px !important;
                    font-size: 20px;
                }
                p{
                    line-height: 15px;
                    font-size: 13px;
                    padding: 20px 0 0 30px;
                    br{
                        display: none;
                    }
                }
            }
        }
        @media (max-width:700px) {
            .figure{
                width: 247px;
                height: 215px;
                left: 0;
                top: 0;
            }
        }
    }
    .about{
        padding: 220px 0;
        background-color: #f8f8f8;
        .wrap{

        }
        .col{
            display: inline-block;
            width: 49%;
            padding-right: 20px;
            &.col1{
                h2{
                    font-family: Lato;
                    font-size: 50px;
                    line-height: 60px;
                    padding-bottom: 40px;
                }
                p{
                    font-family: Lato;
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 1.640625;
                }
            }
            &.col2{
                text-align: right;
                img{
                    position: relative;
                    left: 20px;
                }
            }
    
        }

        @media (max-width:980px) {
            background-color: #fff;
            padding: 20px 0;
            .wrap{
                display: flex;
                flex-direction: column;
            }
            .col{
                width: 100%;
                padding-right: 0px;
                padding-top: 20px;
                img{
                    max-width: 90%;
                }
                &.col1{
                    order: 2;
                    text-align: center;
                    h2{
                        font-size: 24px !important;
                        line-height: 27px !important;
                    }
                    p{
                        font-size: 13px !important;
                    }
                }
                &.col2{
                    order: 1;
                    figure{
                        text-align: center !important;
                        padding: 30px 0;
                        img{
                            max-width: 85%;
                        }
                    }
                }
            }
        }
    }
    section.infos{
        width: 100%;
        font-size: 0;
        height: 680px;
        display: inline-block;
        position: relative;
        
        .content{
           position: absolute;
           top: 50%;
           left: 50%;
           width: 100%;
           transform: translateY(-50%) translateX(-50%);
        }

        .text{
            margin-top: 30px;
            position: relative;
            top: 0;
            transition: all 0.2s ease-in-out;
        }

        i.icon{
            width: 160px;
            height: 160px;
            background-color: #b7d5eb;
            border-radius: 50%;
            display: block;
            position: relative;
            text-align: center;
            margin: 0 auto;
            transition: width 0.1s ease,
                        height 0.1s ease,
                        background-color 0.2s ease;
            
            svg{
                display: block;
                position: absolute;
                width: 60%;
                height: 60%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                transition: width 0.1s ease,
                        height 0.1s ease;
            
            }
        }
        h3{
            font-family: Lato;
            font-size: 30px;
            font-weight: 300;
            color: #013b69;        
            text-align: center;    
            transition: color 0.2s ease;
        }
        p{
            // color: #fff;
            padding: 40px 23%;
            font-family: Lato;
            font-size: 18px;
            font-weight: 100;
            line-height: 1.34765625;
            color: #ffffff;
            display: block;
            text-align: center;
            height: 0;

            transition: height 0.2s ease;
        }


        .info{
            width: 33.33%;
            height: 680px;
            font-size: 14px;
            float: left;
            position: relative;
            
            transition: background 0.2s ease;

            &:hover{
                background: #013b69;
                
                transition: background 0.2s ease;
                
                h3{
                    color: #fff;
                }

                .text{
                    top: 20px;
                }
                // p{
                //     height: auto;
                    
                // }
                i.icon{
                    background-color: #013b69;

                    svg{
                        width: 200px;
                        height: 200px;
                    }
                }
            }
        }
        @media (max-width:980px) {
            height: auto;

            h3{
                padding-top: 10px;
            }
            p{
                font-size: 13px;
            }
            .info{
                width: 100%;
                height: 280px;
                float: none;
                .content{
                    height: 225px;

                    .text{
                        margin-top: 0px;
                    }
                }
                 p{
                    // color: #fff;
                    padding: 20px 13%;
                 }
                &:hover{
                    .text{
                        top: 0;
                    }
                    i.icon{
                        width: 90px;
                        height: 90px;
                        svg{
                            width: 90px;
                            height: 90px;
                        }
                    }
                }
            }
            i.icon {
                width: 90px;
                height: 90px;
            }
        }
    }
    .simple{
        height: 80vh;
        clear: both;
    }
}