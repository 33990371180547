//
// 404
//

.page-404{
    font-family: Lato;
    max-width: 740px;
    margin: 0 auto;
    padding: calc((100vh - (66px + 410px)) / 2) 20px;
    text-align: center;

    .title{
        font-size: 30px;
        margin-bottom: 40px;
    }

    .text{
        font-size: 23px;
        margin-bottom: 40px;
    }

    .error{
        font-size: 80px;
    }

    .link{
        text-decoration: none;
        color: #01467d;
        transition: all ease-in-out 200ms;
        &:hover{
            opacity: 0.8;
        }
    }
}

