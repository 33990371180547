//
// Dúvidas Custom
//

.main-duvidas{
    .header-main{
        margin-bottom: 220px;
        .info-header{
            background: #3b95cb;
            bottom: -100px;
            h1{
                line-height: 62px;
            }
            p{
                line-height: 30px;
            }
        }
        figure{
            width: 100%;
            &.image-mobile{
                display: none;
            }
        }
        
        @media (max-width: 610px) {
            height: 226px;
            margin-bottom: 140px;
            figure{
                display: none;
            }
            figure.image-mobile{
                display: block !important;
            }
            .info-header{
                right: 0;
                left: auto;
                transform: translateX(0);

                width: 250px;
                height: 200px;
                padding: 20px;
                text-align: right;

                h1{
                    line-height: 24px;
                    font-size: 20px;
                }
                p{
                    line-height: 15px;
                    font-size: 13px;
                    padding: 20px 0 0 30px;
                }
            }
        }
    }
    .wrap{
        position: relative;
        padding-top: 50px;
        padding-bottom: 40px;
    }
    .filter{
        font-size: 0;
        z-index: 3;
        position: relative;
       
        a{
            display: inline-block;
            width: 19%;
            margin-left: 1%;
            font-size: 24px;
            text-align: center;
            height: 88px;
            line-height: 88px;
            border-radius: 5px;
            background-color: #346b97;
            margin-bottom: 24px;
            font-size: 24px;
            color: #ffffff;
            font-weight: 100;
            text-decoration: none;

            transition: box-shadow 0.2s ease,   
                        background-color 0.2s ease;

            &.current,
            &.active,
            &:hover{
                box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 8px 10px 0 rgba(0, 0, 0, 0.25);
                background-color: #013b69;
            }
        }
        @media (max-width:980px) {
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 0;
            width: 100%;
            z-index: 1;
            &:before{
                content: '';
                background: url(../img/arrow-select.png); 
                position: absolute;
                width: 14px;
                height: 10px;
                background-size: 100%;
                top: 30px;
                right: 14px;
                transform: rotate(0deg);
                transition: all 0.2s ease-in-out;
    
            }

            &.open{
                z-index: 3;
                &:before{
                    transform: rotate(180deg)
                }
                a{
                    opacity: 1;
                    pointer-events: unset;
                }
            }

            a{
                width: 100%;
                margin: 0;
                background-color: #fff;
                border-radius: 0;
                color: #013b69;
                text-align: left;
                padding-left: 14px; 
                order: 2;
                border-left: #3B95CB 1px solid;
                border-right: #3B95CB 1px solid;
                height: 68px;
                line-height: 68px;

                opacity: 0;
                pointer-events: none;
                transition: opacity 0.2s ease;

                &:last-child{
                    border-bottom: #3B95CB 1px solid;
                }
                
                &:hover{
                    color: #fff;
                }
                
                &.current,
                &.active{
                    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.25), 0 0 0 0 rgba(0, 0, 0, 0.25);
                    border: #3B95CB 1px solid;
                    background-color: #fff;
                    order: 1;
                    
                    opacity: 1;
                    pointer-events: unset;

                    &:hover{
                        color: #013b69;
                    }
                }
            }
        }
    }

    .list{
        padding-top: 100px;
        padding-bottom: 40px;
        position: relative;
        z-index: 2;

        .question{
            background-color: #f2f2f2;
            padding:35px 90px;
            margin-bottom: 20px;
            position: relative;

            .icon-arrow{
                background-image:url(../img/arrow-icon.png); 
                display: inline-block;
                width: 11px;
                height: 11px;
                position: absolute;
                top: 30px;
                right: 40px;
                transform: rotate(180deg);
            }

            .head{
                width: 100%;
                height: 80px;
                line-height: 80px;
                font-size: 24px;
                font-weight: bold;
                color: #013b69;
                @media (max-width:1200px) {
                    font-size: 21px;
                }
            }
            .answer{
                padding: 0 50px 0px 0px;
                max-height: 0;
                opacity: 0;
                pointer-events: none;
                position: relative;

                transition: max-height 0.2s ease 0.2s, 
                padding 0.2s ease 0.2s, 
                opacity 0.2s ease 0.0s;

                &.expand{
                    padding: 0 50px 30px 0px;
                    max-height: 3000px;
                    opacity: 1;
                    pointer-events: unset;
                    transition: max-height 0.2s ease 0.0s,
                    padding 0.2s ease 0.0s,
                    opacity 0.2s ease 0.2s;
                }

                
                .content{
                    border-top: 1px solid #013b69;
                    padding: 20px 0;
                    p{
                        font-size: 22px;
                        font-weight: 100;
                        color: #000000;
                    }
                    a.button-download{
                        position: absolute;
                        right: -15px;
                        bottom: 0;
                        .icon{

                        }
                    }
                }
            }
            &.expand{
                .answer{
                    padding: 0 50px 30px 0px;
                    max-height: 3000px;
                    opacity: 1;
                    pointer-events: unset;
                    transition: max-height 0.2s ease 0.0s,
                    padding 0.2s ease 0.0s,
                    opacity 0.2s ease 0.2s;
                    .icon-arrow{
                        transform: rotate(0)
                    }
                }
            }
        }
        @media (max-width:980px) {
            padding-top: 50px;
            .question{
                padding:20px 20px;
                .head{
                    width: 100%;
                    height: auto;
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #013b69;
                    padding-right: 20px;
                }
                .icon-arrow{
                    background-image:url(../img/arrow-icon.png); 
                    display: inline-block;
                    width: 11px;
                    height: 11px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    transform: rotate(180deg);
                }

                .answer{
                    .content{
                        p{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}