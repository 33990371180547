header.header{
    width: 100%;
    height: 66px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #000000;
  
    .wrap{
        width: 1190px;
        max-width: 95%;
        display: block;
        margin: 0 auto;
        top: 0;
        height: 100%;
    }
    .logo{
        margin-right: 17px;
        height: 100%;
        top: -20px;
        position: relative;
    }

    a,
    li,
    .logo,
    .access,
    .menu{
        display: inline-block;
        font-family: 'lato';
        font-size: 12px;
        letter-spacing: 0.48px;
        text-transform: uppercase;
        font-weight: 300;
    }
    
    .wpml-ls-item,
    .wpml-ls-slot-13,
    .menu-item-has-children{
        display: none !important;
    }

    .menublock{
        display: inline-block;
        width: calc(100% - 120px);
        height: 100%;
        .access{
            display: block;
            float: right;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            .btn{
                &.active{
                    background-color: #fff;
                    color: #3b95cb;
                }
            }
        }
        .menu{
            display: block;
            float: left;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        a[aria-current],.current-menu-item a, .current-page-ancestor a{
            position: relative;
            display: inline;
            &:before{
                content: '';
                height: 2px;
                display: inline;
                position: absolute;
                background: #2b8fde;
                left: 0;
                bottom: -4px;
                width: 100%;

            }
        }
     
        a{
            display: inline-block;
            text-decoration: none;
            color:#fff;
            margin: 0 17px;
            transition: all 0.2s ease-in-out;
            &:hover{
                color: #3b95cb;
            }
        }
        .btn{
            height: 30px;
            line-height: 30px;
            padding: 0 8px;
            border-radius: 5px;
            background-color: #3b95cb;
            &:hover{
                background-color: #fff;
            }
        }
    }
    .burgue-menu{
        width: 26px;
        opacity: 0;
        pointer-events: none;
        display: block;
        position: absolute;
        top: 18px;
        right: 25px;
        svg{
            rect{
                fill: #fff;
            }
        }
    }
    @media (max-width:980px) {
        .menublock{
            opacity: 0;
            pointer-events: none;
        }
        .burgue-menu{
            opacity: 1;
            pointer-events: unset;
        }
    }
}

.page-template-page-download .current-page-ancestor a{
    &:before{
        opacity: 1 !important;            
    }
}

.home, .page-template-page-download{
    a[aria-current],.current-menu-item a, .current-page-ancestor a{
        &:before{
            opacity: 0;            
        }
    }
}

.btn{
    transition: all 0.2s ease-in-out;
}

.overlay-alert{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    z-index: 9;
    width: 100%;
    height: 100%;
    display: none;
}

.wrap{
    width: 1190px;
    max-width: 95%;
    display: block;
    margin: 0 auto;
    top: 0;
}

.co,.mx,.us-es{
    .mc4wp-alert{
        span{
            display: none;
        }
        .es{
            display: block;
        }
    }
}
.br{
    .mc4wp-alert{
        span{
            display: none;
        }
        .br{
            display: block;
        }
    }
}
.us,.co-en,.mx-en,.br-en{
    .mc4wp-alert{
        span{
            display: none;
        }
        .en{
            display: block;
        }
    }
}

.mc4wp-alert{
    position: fixed;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 26px;
    background: #fff;
    font-family: lato;
    color: #3baecb;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
    width: 310px;
    text-align: center;
    line-height: 32px;
    padding: 45px 20px;

    &::before{
        content: "x";
        position: absolute;
        top: 1px;
        right: 3px;
        color: #000;
        opacity: 0.7;
    }
    &.hide{
        opacity: 0;
        transform: translateX(-50%) translateY(-500%);
    }

}
.footer{
    width: 100%;
    background-color: #01467d;
    padding-top: 30px;
    z-index: 2;
    position: relative;

    a{
        transition: all 0.2s ease-in-out;
    }
    
    .wpml-ls-menu-item{
        > a{
            text-transform: uppercase;
            font-size: 13px;
            @media (max-width:980px) {
                text-transform: unset;
            }
            .wpml-ls-native,
            span{
                text-transform: uppercase;
                font-size: 13px !important;
                @media (max-width:980px) {
                    text-transform: unset;
                }
            }
        }
    }
    .wpml-ls-current-language,
    .menu-item-has-children{
        position: relative;
        bottom: 2px;
        @media (max-width:980px) {
            bottom: 0;
            text-transform: unset !important;
        }
        
        > a{
            text-transform: unset !important;
            font-size: 13px;
            border-left: 1px solid #3B95CB;
            padding: 4px 24px 5px 22px !important;
            text-transform: uppercase;
            @media (max-width:980px) {
                pointer-events:none;
                padding: 4px 0px 5px 0px !important;
                text-underline-position: unset;
            }
            display: inline-block !important;
            &::before{
                content:"";
                display: inline-block;
                width:19px;
                height:19px;
                background:url(../img/worlwide.svg);
                background-size: 100%;
                vertical-align: middle;
                margin-right: 7px;
                // background: #2b8fde;
                // 1px solid #3B95CB
                
                @media (max-width:980px) {
                    background:url(../img/worlwide.svg);
                    background-size: 100%;
                    width:15px;
                    height:15px;
                    margin-right: 4px;
                }
            }

            &::after{
                content:"";
                display: inline-block;
                width:9px;
                height:6px;
                background-image:url(../img/arrow-wpml.png);
                vertical-align: middle;
                margin-left: 12px;
            }
            .wpml-ls-native,
            span{
                font-size: 13px !important;
                vertical-align: middle;
            }
        }
        .sub-menu{
            position: absolute;
            bottom: 25px;
            width: 210px;
            padding-left: 13px;
            background: #fff;

            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s ease;
            
            li{
                width: 100%;
                display: inline-block;
                a{
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 300;
                    color:#013b69;
                    span{
                        // text-transform: unset !important;
                        font-size: 13px;
                        color:#013b69;
                    }

                }
                &:hover{
                    a{
                        span{
                            color:#2b8fde;
                        }
                    }
                }
            }
        }
        &:hover{
            .sub-menu{
                opacity: 1;
                pointer-events: unset;
            }
        }
    }
    
    .line{
        display: table;
        width: 100%;
        clear: both;

        &.line2{
            margin-top: 35px;
        }
        
        .logo{
            float: left;
        }
        .socials{
            float: right;
            .social{
                svg{
                    transform: scale(0.8) translateY(-10px);

                    path{
                        transition: all 0.2s ease-in-out;
                    }

                }
                &:hover{
                    svg{
                        transform: scale(0.8) translateY(-10px);
                        path{
                            fill:#2b8fde !important;
                        }
                    }   
                }
            }
        }
    
        .nav-menu{
            float: left;
            li{
                display: inline-block;

                a{
                    color: #fff;
                    font-family: Lato;
                    font-size: 16px;
                    font-weight: 300;
                    text-transform: uppercase;
                    text-decoration: none;
                    display: inline-block;
                    padding: 10px 24px 10px 0;
                    &:hover{
                        color: #2b8fde;
                    }
                }
            }
        }
        .news{
            float: right;
            position: relative;
            @media (max-width:1200px) {
                float: left;
                width: 250px;
                margin: 0 auto;
                top: 16px;
                margin-top: 20px;
                position: relative;
            }
            
            .mc4wp-response{
                position: absolute;
                z-index: 9;
                bottom: 0;
                right: 0;
            }

            .icon{
                width: 47px;
                height: 47px;
                display: block;
                float: left;
                margin-right: 10px;

            }
            label{
                width: calc(100% - 58px);
                font-family: Lato;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
            }
            span{
                position: relative;
            }
            i.iconarrow{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            input{
                width: calc(100% - 58px);
                background-color: #01467d;
                color: #fff;
                border: 0;
                border-bottom: 1px solid #fff;
                height: 26px;
                line-height: 26px;
                padding: 0 8px;
            }
            input[type="submit"]{
                position: absolute;
                border: 0;
                right: -10px;
                width: 39px;
                top: 50%;
                transform: translateY(-50%);
                background-color: transparent;
                opacity: 0;
                cursor:pointer;
                transition: opacity 0.2s ease;
                // &:hover{
                //     opacity: 1;
                // }
            }
        }
        
        
    }    
    .rights{
        height: 45px;
        line-height: 45px;
        background-color: #013b69;
        margin-top: 35px;
        nav{
            float: left;
            width: 60%;
            li{
                float: left;
                a{
                    color: #fff;
                    float: left;
                    font-family: Lato;
                    font-weight: 100;
                    font-size: 15px;
                    text-decoration: underline;
                    margin-left: 20px;
                    @media (max-width:1200px) {
                        margin-left: 12px;
                    }
                    &:hover{
                        color: #3b95cb;
                    }
                }
            }
        }
        .copy{
            float: right;
            font-family: Lato;
            font-size: 14px;
            color: #dadada;
            br{
                display: none;
            }
        }
        .area{
            color: #fff;
            float: left;
            font-family: Lato;
            font-size: 15px;
            text-decoration: none;
            &:hover{
                color: #5cb0f0;
            }
        }

    }
    @media (max-width:980px) {
        .socials{
            float: right;
            .social{
                position: relative;
                top: -15px;
                margin-right: 0px;
                width: 46px;
                display: inline-block;
                svg{
                    transform: scale(1) translateY(0);
                    width: 100%;
                }
            }
        }
        .line{
            .nav-menu{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 170px;
                z-index: 3;
                .menu-item{
                    display: none;
                }
                .wpml-ls-menu-item{
                    display: block;
                    width: 250px;
                    text-align: center;
                }
                .wpml-ls-current-language > a{
                    border-left: 0;
                }
            }
            .news{
                float: none;
                top: -27px;
                max-width: 225px;
                margin: 0 auto;

                
            }
            &.line2{
                margin-top: 25px;
            }
        }
        .rights{
            height: 145px;
            background-color: #013b69;
            margin-top: 45px;
            position: relative;
            .copy{
                float: none;
                clear: both;
                display: block;
                text-align: center;
                font-family: Lato;
                font-size: 10px;
                color: #dadada;
                line-height: 12px;
                padding-top: 20px;
                br{
                    display: block;
                }
            }
            nav{
                width: 70%;
                margin: 0 auto;
                float: none;
                clear: both;
                display: block;
            }
            .menu-footer-br-container{
                position: static;
                width: 100%;
                li{
                    float: left;
                    display: block;
                    height: 25px;
                    clear: none;
                    width: 50%;

                    &:nth-child(3){
                        float: right;
                    }
                    &:nth-child(4){
                        float: right;
                    }
                
                    a{
                        margin-left: 0px;
                        font-size: 11px;
                        float: left;
                    }
                }
            }
            .area{
                color: #fff;
                float: none;
                clear: both;
                font-family: Lato;
                font-size: 12px;
                font-weight: 300;
                display: block;
                text-align: center;
                text-decoration: none;
            }
        }
    }

}

.menu-mobile{
    position: fixed;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    pointer-events: none;
    
    transition: opacity 0.2s ease;

    .wpml-ls-item{
        display: none !important;
    }

    .overlay{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        z-index: 1;
    }

    .nav-mobile{
        width: 251px;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 38px;
        .close{
            position: absolute;
            top: 4px;
            right: 10px;
            svg{
    
            }
        }
        nav.menu{
            text-align: center;
            .nav{
                display: block;
                ul{
                    display: block;
                }
                li{
                    height: 8vh;
                    line-height: 8vh;
                    display: block;
                    
                    a{
                        text-decoration: none;
                        display: block;
                        height: 100%;
                        line-height: 8vh;
                        font-family: Lato;
                        font-size: 16px;
                        font-weight: 500;
                        color: #013b69;
                        text-transform: uppercase;
                    }
                }
            }
        }
        nav.access{
            text-align: center;
            width: 100%;
            position: relative;
            padding-top: 20px;
            &::before{
                content: "";
                width: 75%;
                height: 1px;
                background-color: #013b69;
                position: absolute;
                left: 50%;
                top: 20px;
                transform: translate(-50%)
            }
            .btn{
                width: 180px;
                height: 50px;
                line-height: 50px;
                border-radius: 4px;
                background-color: #013b69;
                font-family: Lato;
                
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                color: #ffffff;

                padding: 0 10px;
                display: block;
                margin: 10% auto;

            }
            .linklogin{
                font-family: Lato;
                font-size: 16px;
                font-weight: 500;
                color: #013b69;
                text-transform: uppercase;
                text-decoration: none;
                height: 8vh;
                line-height: 8vh;
                display: block;
            }
        }
    }

    .btn-about.about-us{
        bottom: 10%;
        right: 55px;
        background-color: #b7d5eb;
        display: none;
        
    }
    
}


.main-home{

    .video{
        width: 100%;
        height: calc(100vh - 66px);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        position: relative;
        // background: no-repeat center center fixed;
        .play-component{
            width: 68px;
            height: 68px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            img{
                max-width: 100%;
                height: auto;
            }
        }
        .wrap{
            height: 200px;
            bottom: 120px;
            left: 50%;
            transform: translateX(-50%);
            top: unset;
            position: absolute;
            
            .title-description{
                font-family: Lato;
                font-size: 60px;
                font-weight: 100;
                color: #fff;
                position: relative;
                left: -4px;
            }
            .text-description{
                font-family: Lato;
                font-size: 22px;
                font-weight: 300;
                color: #fff;
                padding-top: 20px;
            }
        }
        .arrow{
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }

        }
        @media (max-width:980px) {
            background-attachment: initial;
            .wrap{
                height: 200px;
                bottom: unset;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                top: 50%;
                position: absolute;
                width: 267px;
                
                .title-description{
                    font-family: Lato;
                    font-size: 35px;
                    font-weight: 100;
                    color: #fff;
                    position: relative;
                    left: -4px;
                }
                .text-description{
                    font-family: Lato;
                    font-size: 16px;
                    font-weight: 300;
                    color: #fff;
                    padding-top: 20px;
                }
            }
        }
    }
    
    
    .show-uniq{
        width: 100%;
        height: 800px;
        background-color: #013b69;
        text-align: center;
        padding:140px 4% 0;

        h2{
            font-family: Lato;
            font-size: 50px;
            font-weight: 100;
            color: #fff;
            max-width: 864px;
            display: block;
            margin: 0 auto;
        }
        p{
            padding-top: 75px;
            font-family: Lato;
            font-size: 22px;
            font-weight: 100;
            line-height: 1.40625;
            color: #fff;
            max-width: 744px;
            display: block;
            margin: 0 auto;
        }
        figure{
            width: 1040px;
            max-width: 100%;
            margin: 0 auto;
            padding-top: 75px;

            img{
                max-width: 100%;
            }
        }
        @media(max-width:980px){
            height: auto;
            padding: 50px 20px 0;
            h2{
                font-size: 20px;
            }
            p{
                font-size: 13px;
                padding-top: 35px;
            }
            figure{
                padding-top: 0;
                top: 70px;
                position: relative;
            }
        }
    }

    section.features{
        padding-top: 470px;
        padding-bottom: 190px;
        .info{
            display: table;
            width: 100%;
            margin-bottom: 150px;
            &:nth-child(odd){
                .col{
                    &.col1{
                        float: right;
                    }
                    &.col2{
                        float: left;
                    }
                }
            }
            &:nth-child(even){
                col{
                    &.col1{
                        float: left;
                    }
                    &.col2{
                        float: right;
                    }
                }
            }
        }
        .col{
            width: 46%;
            display: inline-block;
            vertical-align: top;
            &.col1{
                float: left;
                figure{
                    width: 100%;
                }
                img{
                    max-width: 100%;
                }
    
            }
            &.col2{
                float: right;
                h2{
                    font-family: Lato;
                    font-size: 60px;
                    font-weight: 100;
                    color: #346b97;
                    padding: 50px 0 30px;
                }
                p{
                    font-family: Lato;
                    font-size: 18px;
                    color: #9b9b9b;
                    padding-right: 35%;
                    display: block;
                    line-height: 25px;
                }
    
            }
        }
        .btn{
            margin: 0 auto;
            display: table;
            &.see-more{
                background-color: #013b69;
                color: #fff;

                &:hover{
                    background-color: #8fcafa;
                    color: #013b69;
                }
            }

        }
        @media (max-width:600px) {
            padding-top: 130px;
            padding-bottom: 70px;
            .info{
                .col{
                    &.col2{
                        margin-bottom: 0px;
                    }
                }
            }
        }
        @media (max-width:980px) {
            padding-top: 140px;
            padding-bottom: 130px;

            .info{
                display: table;
                width: 100%;
                margin-bottom: 100px;

                &:last-child{
                    margin-bottom: 50px;
                }
                &:nth-child(odd){
                    .col{
                        &.col1{
                            float: left;
                        }
                        &.col2{
                            float: right;
                        }
                    }
                }
                // &:nth-child(even){
                //     col{
                //         &.col1{
                //             float: left;
                //         }
                //         &.col2{
                //             float: right;
                //         }
                //     }
                // }
            }
            .col{
                width: 96%;
                display: block;
                &.col1{
                    float: unset;
                    margin-bottom: 10px;
                    figure{
                        width: 100%;
                        text-align: center;
                    }
                    img{
                        max-width: 100%;
                    }
        
                }
                &.col2{
                    float: unset;
                    margin-bottom: 50px;
                    text-align: center;
                    h2{
                        font-family: Lato;
                        font-size: 24px;
                        font-weight: 100;
                        color: #346b97;
                        padding: 0 0 10px;
                    }
                    p{
                        font-family: Lato;
                        font-size: 13px;
                        color: #9b9b9b;
                        padding: 0 25px;
                        display: block;
                        line-height: 16px;
                    }
        
                }
            }
        }
        
    }

}
section.simple,
section.sign{
    width: 100%;
    height: calc(100vh - 66px);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    text-align: center;
    .content{
        width: 570px;
        max-width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    h2{
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 50px;
        font-weight: 300;
        color: #fff; 
    }
    p{
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 18px;
        line-height: 22px;
        font-weight: 100;
        color:#fff;
        padding: 60px 0;
    }
    a.btn{
        &.sing{
            background-color: #fff;
            color: #013b69;
        }
        &:hover{
            background-color: #013b69;
            color: #fff;
        }
    }
    @media (max-width:980px) {
        h2{
            font-size: 24px;
            font-weight: 100;
        }
        p{
            font-size: 18px;
            line-height: 22px;
            font-size: 13px;
            padding: 30px 0;
        }
    }
}

.btn{
    height: 65px;
    line-height: 65px;
    border-radius: 5px;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 50px;
    display: inline-block;
    text-decoration: none;
}

@media (max-width:980px) {
    .btn{
        height: 38px;
        line-height: 38px;
        border-radius: 5px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 24px;
        display: inline-block;
        text-decoration: none;
    }
}

.block-bottom,
.header-main{
    width: 100%;
    height: 800px;
    position: relative;
    .info-header{
        width: 635px;
        height: 515px;
        background-color: #346b97;
        padding: 72px 142px 0 66px;
        position: absolute;
        bottom: 76px;
        left: 50%;
        transform: translateX(-95px);
        z-index: 2;


        h1{
            font-family: Lato;
            font-size: 55px;
            font-weight: 100;
            color: #fff;
        }
        p{
            font-family: Lato;
            font-size: 22px;
            font-weight: 100;
            color: #fff;
            padding: 40px 70px 0 0;
        }
    }
    figure{
        width: 50%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        z-index: 1;
        background-size: cover;
    }
}


.btn-about.about-us{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    position: fixed;
    right: -5px;
    width: 144px;
    height: 57px;
    background-color: #fff;
    border-radius:5px;
    display: none;
    bottom:130px;
    z-index: 9999;
    padding: 12px 20px 16px;
    text-decoration: none;
    transition: background-color 0.2s ease;
    &:hover{
        background-color: #8fcafa;
    }
    .icon{
        display: block;
        float: right
    }
    span{
        text-align: center;
        width: 68px;
        display: inline-block;
        color:#000;
        
    }

}

@media(max-width: 980px){
    footer{
        .btn-about.about-us{
            display: none;
        }
    }
}

.menu-open{
    .menu-mobile{
        opacity: 1;
        pointer-events: unset;
    }
}

.modal-adquira{
    // display: none;
}

input:focus {
    outline:none;
}